import type { ReactElement } from 'react'

import Accordion from '../../templateComponents/Workspace/plugins/components/Accordion/Accordion'

type Props = {
  description?: string
  additionalDescriptions: Core.AdditionalProductDescription[]
} & TranslateProps

export default function ProductDescription({
  t,
  description,
  additionalDescriptions,
}: Readonly<Props>): ReactElement | null {
  if (!description && !additionalDescriptions.length) return null

  const accordionData = additionalDescriptions.map(({ headline, htmlDescription }) => ({
    title: headline,
    content: htmlDescription,
  }))

  return (
    <div className="product-addition">
      <h2 className="product-addition-headline">{t('components.productComponent.description')}</h2>
      {description && (
        <div
          className="product-addition-content"
          itemProp="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      <div className="product-addition-expander">
        <Accordion
          data={accordionData}
          titleClassName={'product-addition-headline'}
          innerTextClassName={'product-addition-expander-text'}
        />
      </div>
    </div>
  )
}
