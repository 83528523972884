const ORDER_UNIT_PIECE_TRANSLATIONS = [
  'peça/peces',
  'kus(y)',
  'stk.',
  'Stück',
  'piece(s)',
  'unidad(es)',
  'kpl',
  'unité(s)',
  'pezzo/i',
  'stuk(s)',
  'peça(s)',
  'штука (-и)',
  'styck',
]

export function hasOrderUnitPiece(value: string): boolean {
  return ORDER_UNIT_PIECE_TRANSLATIONS.some((translation) => value === translation)
}

export type QuantityFieldError = 'minimumQuantityNotReached' | 'invalidQuantityIncrement'

export function validateQuantity(quantity: number, minOrder: number, intervalOrder: number): QuantityFieldError[] {
  const errors: QuantityFieldError[] = []

  if (quantity < minOrder) errors.push('minimumQuantityNotReached')

  // Assuming merchants not to offer units smaller than 3 zeros, multiply by 1000
  // will turn all numbers to integers, and we don't get floating point precision issues.
  if ((quantity * 1000 - minOrder * 1000) % (intervalOrder * 1000) !== 0) errors.push('invalidQuantityIncrement')

  return errors
}
