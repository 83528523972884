import type { ReactNode } from 'react'
import { filter, isEqual, omit } from 'lodash'
import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import { GotoContext } from '../GotoProvider'
import { generateProductUrl, withQueryAndScope } from '../../urlGenerators'
import compose from '../../utils/compose'
import translate from '../../utils/translate'
import withI18n from '../../components/withI18n'

type SelectionState = Record<string, string>
type Option = { value: string; label: string }
type VariationItemWithAvailability = Frontend.VariationItem & Required<Pick<Frontend.VariationItem, 'availability'>>

/**
 * This converts a list of VariationAttributeSelections to a
 * SelectionState. A SelectionState is easier to use for comparison. In
 * particular because it shares the same structure as the `attributes`
 * object found on `VariationItem`
 */
function toSelectionState(selection: Core.VariationAttributeSelection[] | null): SelectionState {
  const state = Object.create(null)
  for (const { name, value } of selection || []) state[name] = value
  return state
}

function hasDefinedAvailability(variation: Frontend.VariationItem): variation is VariationItemWithAvailability {
  return typeof variation.availability === 'object' && Boolean(variation.availability)
}

/**
 * A variation attribute should show itself as `Out of Stock` if all its
 * variations are considered either unpurchasable or out of stock.
 *
 * If a single of its variation is considered in stock or all products
 * are not available, it should not show out of stock.
 */
function shouldShowOutOfStock(variations: VariationItemWithAvailability[]) {
  const total = variations.length
  let outOfStock = 0
  let unavailable = 0

  for (const variation of variations) {
    if (!variation.availability.available) unavailable++
    else if (!variation.availability.inStock) outOfStock++
    else if (variation.availability.inStock) return false
  }

  return total > 0 && outOfStock + unavailable === total && outOfStock > 0
}

/**
 * A variation attribute should shown itself as `Not Available` if all its
 * variations are considered unpurchasable.
 */
function shouldShowNotAvailable(variations: VariationItemWithAvailability[]) {
  return variations.length > 0 && variations.every(({ availability }) => !availability.available)
}

function VariationSelection({ product, t }: Readonly<{ product: Frontend.Product } & TranslateProps>): ReactNode {
  const location = useSelector<State, ImmutableMap>((state) => state.get('location'))
  const { gotoState } = useContext(GotoContext)

  // if it is a variation product, prefill the selected attributes with those from the product
  const [selectionState, setSelectionState] = useState(toSelectionState(product.productVariationSelection))

  function handleChange(attributeName: string, value: string) {
    const newState = { ...selectionState, [attributeName]: value }
    setSelectionState(newState)

    // check if all attributes are selected
    const allFilled = Object.keys(newState).length === product.variations?.variationAttributes.length

    // check if the selected attributes are already those of the current variation product
    const theSame = isEqual(newState, toSelectionState(product.productVariationSelection))

    if (allFilled && !theSame) {
      // find the variation that attribute values are the same as the selected ones
      const result = product.variations?.items.find((variation) => isEqual(variation.attributes, newState))

      // navigate to the variation product
      if (result) {
        gotoState({
          ...withQueryAndScope({ pathname: generateProductUrl(result) }, location),
          state: { scrollToTop: false },
        })
      }
    }
  }

  function createAvailabilityText(
    possibleVariationsLimitedByAttributeValue: Frontend.VariationItem[],
  ): string | undefined {
    const allHasAvailability = possibleVariationsLimitedByAttributeValue.every(hasDefinedAvailability)

    // in this case, there's no variations or we have no defined availability, so no availability text,
    if (possibleVariationsLimitedByAttributeValue.length === 0 || !allHasAvailability) return undefined

    if (shouldShowOutOfStock(possibleVariationsLimitedByAttributeValue))
      return t('components.productComponent.variationSelectionDropdown.availability.outOfStock')

    if (shouldShowNotAvailable(possibleVariationsLimitedByAttributeValue))
      return t('components.productComponent.variationSelectionDropdown.availability.notAvailable')

    // here we know that at least one of the variations is available and
    // we don't need any availabilty text
    return undefined
  }

  function createVariationLabel(
    possibleVariations: Frontend.VariationItem[],
    attributeName: string,
    variationAttribute: Core.VariationAttributeValue,
  ): string {
    const { displayValue: label } = variationAttribute
    if (!variationAttribute.displayValue) return ''

    // if the attribute variation we are dealing with is selected, we
    // only want to display the text as the status should show elsewhere,
    const isSelected = selectionState[attributeName] === variationAttribute.value
    if (isSelected) return label

    const possibleVariationsLimitedByAttributeValue = possibleVariations.filter(
      (x) => x.attributes[attributeName] === variationAttribute.value,
    )
    const availabilityText = createAvailabilityText(possibleVariationsLimitedByAttributeValue)

    return availabilityText ? `${label} (${availabilityText})` : label
  }

  function renderSelection(attributeName: string, value?: string) {
    // get the values for this variation
    const variationValues = product.variations?.variationAttributes.find((attr) => attr.name === attributeName)?.values

    // transform the possible variations of this attribute into an options-list for the dropdown
    const possibleVariations = filter(product.variations?.items, { attributes: omit(selectionState, attributeName) })
    const variationKeys = new Set(possibleVariations.map((variation) => variation.attributes[attributeName]))

    // sort the variation options by the order of the variation values
    const sortedVariationOptions: Option[] = []
    if (!value) {
      sortedVariationOptions.push({
        // the default option for the dropdown
        value: '',
        label: t('components.productComponent.variationSelectionDropdown.label'),
      })
    }

    variationValues?.forEach((variationAttributeValue) => {
      if (variationKeys.has(variationAttributeValue.value))
        sortedVariationOptions.push({
          value: variationAttributeValue.value,
          label: createVariationLabel(possibleVariations, attributeName, variationAttributeValue),
        })
    })

    return (
      <select
        required
        // prevent Firefox from reusing old selection upon page reload (triggers hydration warning)
        autoComplete="off"
        suppressHydrationWarning
        form="add-to-cart-button"
        className="ep-form-text-field"
        name={attributeName}
        value={value}
        onChange={(event) => handleChange(attributeName, event.target.value)}
        title={t('components.productComponent.variationSelectionDropdown.explanation')}
      >
        {sortedVariationOptions.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    )
  }

  const attributes = product.variations?.variationAttributes || []

  if (!product.variations?.items) return null

  return (
    <div className="product-variation">
      {attributes.map(({ displayName, name }) => (
        <label className="product-variation-type" key={name}>
          <span className="product-variation-label">{displayName}:</span>
          <div className="product-variation-dropdown">{renderSelection(name, selectionState[name])}</div>
        </label>
      ))}
    </div>
  )
}

const TranslatedVariationSelection = compose(withI18n('shop'), translate())(VariationSelection)

export default TranslatedVariationSelection
