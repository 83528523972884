import type { ChangeEvent, FocusEvent, HTMLAttributes, KeyboardEvent, ReactElement } from 'react'
import { omit } from 'lodash'
import { useRef } from 'react'

import { hasOrderUnitPiece } from '../../utils/orderUnits'
import { useProductDetailDispatch, useProductDetailState } from './ProductDetailProvider'
import translate from '../../utils/translate'

export type QuantityFieldProps = {
  className?: string
  min?: number
  quantity?: number | string
  invalidProduct?: boolean
  orderUnitInfo: Core.OrderUnitInfo
} & TranslateProps &
  HTMLAttributes<HTMLElement>

function QuantityField(props: Readonly<QuantityFieldProps>): ReactElement {
  const { className = '', min: min_ = 1, t, invalidProduct = false, orderUnitInfo } = props

  const min = orderUnitInfo.minOrder ?? min_
  const delta = orderUnitInfo.intervalOrder ?? 1

  const state = useProductDetailState()
  const dispatch = useProductDetailDispatch()

  const furtherHtmlAttributes = omit(props, ['className', 'min', 'quantity', 'invalidProduct', 'orderUnitInfo', 't'])

  const disabled = state.isBusy || invalidProduct

  const inputRef = useRef<HTMLInputElement>(null)

  const handleDecrease = () => {
    dispatch({
      type: 'quantity/decrease',
      delta,
      min,
    })

    // Waiting for all event handlers to finish, then blur the input field.
    // This solves an issue of undesired focus states on touch devices (UNITY-5984).
    setTimeout(() => inputRef.current?.blur())
  }

  const handleIncrease = () => {
    dispatch({
      type: 'quantity/increase',
      delta,
      min,
    })

    // Waiting for all event handlers to finish, then blur the input field.
    // This solves an issue of undesired focus states on touch devices (UNITY-5984).
    setTimeout(() => inputRef.current?.blur())
  }

  const setQuantity = (nextValue: string) => {
    const pattern = orderUnitInfo.orderUnit === 'piece' ? /^(\d)*$/g : /^([0-9,.])*$/

    if (pattern.test(nextValue)) {
      dispatch({
        type: 'quantity/set',
        quantity: nextValue,
        min,
      })
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleBlur(event as unknown as FocusEvent<HTMLInputElement>)
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const value = event.target.value.trim()

    if (!state.isBusy) setQuantity(value === '' ? String(min) : value)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuantity(event.target.value.trim())
  }

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.target.setSelectionRange(0, event.target.value.length)
  }

  return (
    <label htmlFor="quantity" className={`${className}-label`} {...furtherHtmlAttributes}>
      {/* Aria hiding the label as the input's accessible name will */}
      {/* otherwise include the label AND the buttons' aria-labels. */}
      {/* Instead, the input element has it's own aria-label in case withLabel=true */}
      <span aria-hidden="true">{t('productQuantity.label')}</span>
      <div className={className}>
        <button
          onClick={handleDecrease}
          disabled={disabled || state.quantityFieldValue === ''}
          aria-label={
            orderUnitInfo.intervalOrder !== 1
              ? t('productOrderUnitQuantity.decreaseButton.accessibilityLabel', {
                  value: orderUnitInfo.intervalOrder,
                  unit: orderUnitInfo.orderUnitShort,
                })
              : t('productQuantity.decreaseButton.accessibilityLabel')
          }
        />
        <input
          ref={inputRef}
          id="quantity"
          inputMode="numeric"
          pattern="[0-9]*"
          min={min}
          value={state.quantityFieldValue}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          autoComplete="off"
          disabled={disabled}
          data-testid="quantityInputField"
          aria-label={t('productQuantity.label')}
        />
        <button
          onClick={handleIncrease}
          disabled={disabled || state.quantityFieldValue === ''}
          aria-label={
            orderUnitInfo.intervalOrder !== 1
              ? t('productOrderUnitQuantity.increaseButton.accessibilityLabel', {
                  value: orderUnitInfo.intervalOrder,
                  unit: orderUnitInfo.orderUnitShort,
                })
              : t('productQuantity.increaseButton.accessibilityLabel')
          }
        />
        {!hasOrderUnitPiece(orderUnitInfo.orderUnitShort) && (
          <span className="product-quantity-field-order-unit">{orderUnitInfo.orderUnitShort}</span>
        )}
      </div>
    </label>
  )
}

export default translate('components.productComponent')(QuantityField)
