import { hasOrderUnitPiece } from './orderUnits'

const formatter = (locale: string, currency: string) =>
  new Intl.NumberFormat(locale.replace('_', '-'), {
    style: 'currency',
    currency,
  })

export function findBulkPrice(bulkPrices: Core.BulkPrice[], quantity: number): Core.BulkPrice | undefined {
  return bulkPrices
    .slice() // copy array for sorting
    .sort((a, b) => b.quantity.amount - a.quantity.amount)
    .find((bulkPrice) => quantity >= bulkPrice.quantity.amount)
}

export function getBulkPriceWhenExists(bulkPrices: Core.BulkPrice[] | null, quantity: number): string | undefined {
  const bulkPrice = findBulkPrice(bulkPrices ?? [], quantity)

  return bulkPrice?.basePrice?.formatted ?? bulkPrice?.price.formatted
}

export function renderPriceSavingsWhenQuantityIsReached(
  locale: string,
  bulkPrices: Core.BulkPrice[] | undefined | null,
  quantity: number,
  lineItemCouponDiscount: Core.Price | null,
): string | null {
  const bulkPrice = findBulkPrice(bulkPrices ?? [], quantity)

  const couponDiscount = lineItemCouponDiscount?.amount ?? 0

  if (!bulkPrice) {
    if (lineItemCouponDiscount && couponDiscount > 0) {
      return formatter(locale, lineItemCouponDiscount.currency).format(couponDiscount)
    }

    return null
  }

  return bulkPrice?.priceSavings?.percent && bulkPrice.priceSavings.percent.percentage > 0
    ? new Intl.NumberFormat(locale.replace('_', '-'), {
        style: 'currency',
        currency: bulkPrice.price.currency,
      }).format(
        (bulkPrice.priceSavings.regularBulkPrice.amount / 100) * bulkPrice.priceSavings.percent.percentage * quantity +
          couponDiscount,
      )
    : couponDiscount > 0
      ? formatter(locale, bulkPrice?.price.currency).format(couponDiscount)
      : null
}

export function renderPriceSavingsBasePriceWhenExists(
  bulkPrices: Core.BulkPrice[] | null,
  quantity: number,
  orderUnitInfo: Core.OrderUnitInfo,
  locale: Core.FullShop['locale'],
): string | null {
  const bulkPrice = findBulkPrice(bulkPrices ?? [], quantity)

  if (!bulkPrice) return null

  const formatter = new Intl.NumberFormat(locale.replace('_', '-'))

  const price = bulkPrice.price.formatted

  if (orderUnitInfo.priceQuantity !== 1) {
    return `${price} / ${formatter.format(orderUnitInfo.priceQuantity)} ${orderUnitInfo.orderUnit}`
  }

  return hasOrderUnitPiece(orderUnitInfo.orderUnitShort) ? price : `${price} / ${orderUnitInfo.orderUnit}`
}

export function getBulkPriceAmount(bulkPrices: Core.BulkPrice[], quantity: number): number | undefined {
  return findBulkPrice(bulkPrices, quantity)?.price.amount
}

export function getBulkPrice(product: Frontend.Product, quantity: number): Core.Price | null {
  const bulkPrice = findBulkPrice(product.bulkPrices ?? [], quantity)

  return bulkPrice ? bulkPrice.price : product.price
}
