import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { generateProductUrl, withQueryAndScope } from '../../urlGenerators'

interface ShareButtonProps {
  product: Frontend.Product
  shopBaseUrl: URL
  t: (key: string) => string
}

enum SocialSharingApps {
  Facebook = 'Facebook',
  Pinterest = 'Pinterest',
  WhatsApp = 'WhatsApp',
  CopyLink = 'CopyLink',
}

function ShareButton({ t, product, shopBaseUrl }: Readonly<ShareButtonProps>) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const checkmarkRef = useRef<HTMLLIElement>(null)
  const [canNativelyShareProduct, setCanNativelyShareProduct] = useState(false)
  const location = useSelector<State, ImmutableMap>((state) => state.get('location'))

  const productNativeShare = new URL(withQueryAndScope(generateProductUrl(product), location), shopBaseUrl)
  productNativeShare.searchParams.append('marketingChannel', 'SocialSharing')
  productNativeShare.searchParams.append('marketingSubchannel', 'NativeShare')

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleClick = () => {
    if (canNativelyShareProduct) {
      shareProduct()
    } else {
      toggleDropdown()
    }
  }

  const handleBlur = () => {
    if (!canNativelyShareProduct) {
      setTimeout(() => {
        if (!dropdownRef.current || dropdownRef.current.contains(document.activeElement)) {
          return
        }
        setIsDropdownOpen(false)
      }, 200)
    }
  }

  const triggerCheckmarkAnimation = () => {
    if (checkmarkRef.current) {
      checkmarkRef.current.classList.add('product-sharing-dropdown-item-button-copylink-checkmark-icon-active')
      setTimeout(() => {
        checkmarkRef.current?.classList.remove('product-sharing-dropdown-item-button-copylink-checkmark-icon-active')
      }, 2000)
    }
  }

  const handleShare = (platform: string) => {
    let shareUrl = new URL('https://www.epages.com/')
    const productDesktopShare = new URL(withQueryAndScope(generateProductUrl(product), location), shopBaseUrl)
    productDesktopShare.searchParams.append('marketingChannel', 'SocialSharing')
    productDesktopShare.searchParams.append('marketingSubchannel', platform)

    switch (platform) {
      case SocialSharingApps.Facebook:
        shareUrl = new URL('https://www.facebook.com/sharer/sharer.php')
        shareUrl.searchParams.append('u', productDesktopShare.toString())
        break

      case SocialSharingApps.Pinterest:
        shareUrl = new URL('https://pinterest.com/pin/create/button')
        shareUrl.searchParams.append('url', productDesktopShare.toString())
        shareUrl.searchParams.append('description', product.name)
        if (product.image) {
          shareUrl.searchParams.append('media', product.image.url)
        }
        break

      case SocialSharingApps.WhatsApp:
        shareUrl = new URL('https://api.whatsapp.com/send')
        shareUrl.searchParams.append('text', productDesktopShare.toString())
        break

      case SocialSharingApps.CopyLink:
        copyLink(productDesktopShare.toString())
        triggerCheckmarkAnimation()
        return

      default:
        return
    }

    setIsDropdownOpen(false)
    return window.open(shareUrl.toString(), '_blank', 'noreferrer')
  }

  const copyLink = (productUrl: string) => {
    if ('ClipboardItem' in window) {
      const clipboardData = new ClipboardItem({
        'text/plain': new Blob([productUrl], { type: 'text/plain' }),
      })
      navigator.clipboard.write([clipboardData])
    } else {
      // For Firefox or other browsers not supporting ClipboardItem API
      navigator.clipboard.writeText(productUrl)
    }
  }

  useEffect(() => {
    if (navigator.userAgent.includes('Mobi') && navigator.canShare?.({ url: productNativeShare.toString() })) {
      setCanNativelyShareProduct(true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const shareProduct = () => {
    navigator.share({ url: productNativeShare.toString() })
  }

  return (
    <div className="product-sharing" ref={dropdownRef}>
      <button
        className="product-sharing-button"
        onClick={handleClick}
        onBlur={handleBlur}
        // Safari does not close social sharing list when tabIndex is not included
        tabIndex={0}
      >
        {t('components.productComponent.socialSharing.button.label')}
      </button>
      {!canNativelyShareProduct && isDropdownOpen && (
        <div className="product-sharing-dropdown-list-container" data-testid="product-sharing-dropdown-list-container">
          <ul className="product-sharing-dropdown-list">
            {Object.values(SocialSharingApps).map((app) => (
              <li className="product-sharing-dropdown-item" key={app}>
                {app === 'CopyLink' ? (
                  <button
                    className={`product-sharing-dropdown-item-button-${app.toLowerCase()}`}
                    onClick={() => handleShare(app)}
                    onBlur={handleBlur}
                    // Safari skips checkMarkAnimation if tabIndex is not included
                    tabIndex={0}
                  >
                    <i
                      className="product-sharing-dropdown-item-button-copylink-checkmark-icon fa fa-check"
                      data-testid="product-sharing-dropdown-item-button-copylink-checkmark-icon"
                      ref={checkmarkRef}
                      aria-hidden="true"
                    ></i>
                    <i className="product-sharing-dropdown-item-button-copylink-icon fa fa-link" aria-hidden="true"></i>
                    <span>{t(`components.productComponent.socialSharing.${app.toLowerCase()}Button.label`)}</span>
                  </button>
                ) : (
                  <button
                    className={`product-sharing-dropdown-item-button-${app.toLowerCase()}`}
                    onClick={() => handleShare(app)}
                  >
                    {t(`components.productComponent.socialSharing.${app.toLowerCase()}Button.label`)}
                  </button>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ShareButton
