import { getBulkPriceAmount } from '../../utils/bulkPrices'
import { useProductDetailState } from './ProductDetailProvider'
import TemplatePercentage from '../templateComponents/Percentage'

export interface PercentageProps {
  className?: string
  priceAmount: number
  strikePriceAmount: number
  bulkPrices: Core.BulkPrice[] | null
}

export default function Percentage({
  priceAmount,
  strikePriceAmount,
  bulkPrices,
  className,
}: Readonly<PercentageProps>) {
  const { quantity } = useProductDetailState()

  const bulkPriceAmount = bulkPrices ? getBulkPriceAmount(bulkPrices, quantity) : undefined

  return (
    <TemplatePercentage oldPrice={strikePriceAmount} newPrice={bulkPriceAmount ?? priceAmount} className={className} />
  )
}
