import Immutable, { is as isEqual } from 'immutable'
import { Component } from 'react'
import { connect } from 'react-redux'
import { map } from 'react-immutable-proptypes'
import { number } from 'prop-types'

import { addLegacyProductProperties, track } from '../utils/tracking'
import { addProduct, setAction } from '../utils/googleAnalytics'
import { createMultiLanguageLinks } from './utils/createMultiLanguageLinks'
import { getPlain } from '../store/utils'
import { getSocialSharingEnabled, loadCart, loadProduct } from '../store/actions'
import Theme from '../components/Theme'

export class ProductRaw extends Component {
  static propTypes = {
    viewError: map,
    shop: map,
    product: map,
    cart: map,
    sellingCountryId: number,
  }

  static storeUpdate = (props, state) => {
    const isForwardNavigation = props.location.action === 'PUSH'
    const productId = props.params.productId
    // look for the GUID to the requested slug somewhere in the state in order to save a db roundtrip
    let guid

    const categoryProducts = state
      .get('categoryProductData', Immutable.Map())
      .valueSeq()
      .map((a) => a.products)
      .reduce((a, b) => a.concat(b), [])
    const searchProducts = state
      .get('searchData', Immutable.Map())
      .valueSeq()
      .map((a) => a.products)
      .reduce((a, b) => a.concat(b), [])
    const productWithSlug = [...categoryProducts, ...searchProducts]
      .filter(Boolean)
      .find((product) => product.slug === productId)

    if (productWithSlug) guid = productWithSlug.productId

    const updates = []

    if (!state.hasIn(['shop', 'socialSharingEnabled'])) updates.push(getSocialSharingEnabled())

    if (!state.get('cart')) updates.push(loadCart())
    if (isForwardNavigation || !state.getIn(['products', productId])) {
      // Note: This call should match with the `loadProduct` call in
      // `maybeRedirectProduct` in "serveApp/redirects".
      updates.push(
        loadProduct(
          {
            guid: guid || productId,
            slug: guid && productId,
          },
          props.sellingCountryId,
        ),
      )
    }

    return updates
  }

  static meta = (props, state) => {
    const product = state.get('products').get(props.params.productId)
    if (!product) return []

    const shop = state.get('shop')

    const title = product.get('title') || product.get('name')
    const viewTitle = product.get('title') ? title : [title, shop.get('title')].filter(Boolean).join(' - ')

    // In multi language shops, the default locale will not get a localized path, but any other language requires it.
    const pathLocale = shop.get('defaultLocale') === shop.get('locale') ? '' : `/${shop.get('locale').substr(0, 2)}`
    const baseHref = shop.getIn(['locationBase', 'origin']) + pathLocale

    const meta = [{ title: viewTitle }]

    meta.push(
      { property: 'og:type', content: 'product' },
      { property: 'og:title', content: title },
      { property: 'og:url', content: baseHref + product.get('href') },
    )

    if (product.get('image')) {
      let productImageUrl = product.getIn(['image', 'url'])

      if (!productImageUrl.startsWith('http'))
        productImageUrl = shop.getIn(['locationBase', 'origin']) + productImageUrl

      meta.push(
        { property: 'og:image', content: productImageUrl },
        { property: 'og:image:width', content: product.getIn(['image', 'width']) },
        { property: 'og:image:height', content: product.getIn(['image', 'height']) },
      )
    }

    if (product.get('price')) {
      meta.push(
        { property: 'og:price:amount', content: product.getIn(['price', 'amount']) },
        { property: 'og:price:currency', content: product.getIn(['price', 'currency']) },
      )
    }

    if (product.get('metaDescription')) {
      meta.push(
        { name: 'description', content: product.get('metaDescription') },
        { property: 'og:description', content: product.get('metaDescription') },
      )
    }

    return meta
  }

  static link = (props, state) => {
    const product = state.get('products').get(props.params.productId)
    if (!product) return []

    const shop = state.get('shop')

    // In multi language shops, the default locale will not get a localized path, but any other language requires it.
    const pathLocale = shop.get('defaultLocale') === shop.get('locale') ? '' : `/${shop.get('locale').substr(0, 2)}`
    const baseHref = shop.getIn(['locationBase', 'origin']) + pathLocale

    return product.get('isVariationProduct')
      ? [
          {
            rel: 'canonical',
            href: `${baseHref}/p/${product.getIn(['variationMaster', 'slug'])}`,
          },
        ]
      : createMultiLanguageLinks(
          'product',
          product.get('slugs', Immutable.List()).toJS(),
          shop,
          product.get('productId'),
        )
  }

  static loadableSsrChunks = ['Product']
  static contentCreationDisabled = () => true

  componentDidMount() {
    const { product, cart } = this.props

    if (product) {
      this.trackProductDetails(product, cart)
    }
  }

  componentDidUpdate(lastProps) {
    const { product, cart } = this.props

    if (product && !isEqual(lastProps.product, product)) {
      this.trackProductDetails(product, cart)
    }
  }

  trackProductDetails(product, cart) {
    track('product:view', {
      product: addLegacyProductProperties(getPlain(product), this.props.shop.get('beyond')),
      cart: getPlain(cart),
    })
    addProduct(product, ['id', 'name', 'variant', 'brand', 'price', 'currency'])
    setAction('detail')
  }

  render() {
    const { viewError, product } = this.props

    if (viewError) return <Theme withLayout error={viewError} currentView="product" />
    if (!product) return null

    return (
      <Theme withLayout currentView="product">
        {(renderView, props) =>
          renderView('Product', {
            ...props,
            product: product.toJS(),
          })
        }
      </Theme>
    )
  }
}

export default connect((state, props) => ({
  viewError: state.getIn(['view', 'error']),
  shop: state.get('shop'),
  product: state.get('products').get(props.params.productId),
  cart: state.get('cart'),
  sellingCountryId: state.getIn(['shop', 'sellingCountryId']) || null,
}))(ProductRaw)
