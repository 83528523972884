import type { Dispatch, PropsWithChildren, Reducer } from 'react'
import { createContext, useContext, useEffect, useReducer } from 'react'

const ProductDetailState = createContext<ProductDetailState | undefined>(undefined)
const ProductDetailDispatch = createContext<Dispatch<ProductDetailAction> | undefined>(undefined)

export type ProductDetailState = {
  isBusy: boolean
  quantity: number
  quantityFieldValue: string
  customizedText?: string
}

export type ProductDetailAction =
  | { type: 'update'; payload: Partial<ProductDetailState> }
  | { type: 'quantity/decrease'; delta: number; min: number }
  | { type: 'quantity/increase'; delta: number; min: number }
  | { type: 'quantity/set'; quantity: string; min: number }

function calculateNearestQuantity(quantity: number, min: number, delta: number): number {
  // Assuming merchants not to offer units smaller than 3 zeros, multiply by 1000
  // will turn all numbers to integers, and we don't get floating point precision issues.
  return (Math.round((quantity - min) / delta) * (delta * 1000) + min * 1000) / 1000
}

export function productDetailReducer(state: ProductDetailState, action: ProductDetailAction): ProductDetailState {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload }
    case 'quantity/decrease': {
      const nearestQuantity = calculateNearestQuantity(state.quantity, action.min, action.delta)

      const nextQuantity = Math.max(
        state.quantity > nearestQuantity ? nearestQuantity : (nearestQuantity * 1000 - action.delta * 1000) / 1000,
        action.min,
      )

      return { ...state, quantity: nextQuantity, quantityFieldValue: String(nextQuantity) }
    }
    case 'quantity/increase': {
      if (state.quantity < action.min) return { ...state, quantity: action.min, quantityFieldValue: String(action.min) }

      const nearestQuantity = calculateNearestQuantity(state.quantity, action.min, action.delta)

      const nextQuantity =
        state.quantity < nearestQuantity ? nearestQuantity : (nearestQuantity * 1000 + action.delta * 1000) / 1000

      return { ...state, quantity: nextQuantity, quantityFieldValue: String(nextQuantity) }
    }
    case 'quantity/set': {
      if (action.quantity === '') return { ...state, quantity: 0, quantityFieldValue: '' }

      const quantity = Number(action.quantity.replace(',', '.'))

      if (Number.isNaN(quantity)) return state

      return { ...state, quantity, quantityFieldValue: action.quantity }
    }
    default:
      return state
  }
}

export function ProductDetailProvider({
  productId,
  minQuantity = 1,
  children,
}: Readonly<
  PropsWithChildren<{
    productId: string
    minQuantity?: number
  }>
>) {
  const [state, dispatch] = useReducer<Reducer<ProductDetailState, ProductDetailAction>>(productDetailReducer, {
    isBusy: false,
    quantity: 1,
    quantityFieldValue: '1',
  })

  useEffect(() => {
    dispatch({
      type: 'quantity/set',
      quantity: String(minQuantity),
      min: minQuantity,
    })
  }, [dispatch, minQuantity, productId])

  return (
    <ProductDetailDispatch.Provider value={dispatch}>
      <ProductDetailState.Provider value={state}>{children}</ProductDetailState.Provider>
    </ProductDetailDispatch.Provider>
  )
}

export function useProductDetailState(): ProductDetailState {
  const ctx = useContext(ProductDetailState)

  if (!ctx) {
    throw new Error('Context value undefined. Hook useProductDetailState must be used within a ProductDetailProvider')
  }

  return ctx
}

export function useProductDetailDispatch(): Dispatch<ProductDetailAction> {
  const ctx = useContext(ProductDetailDispatch)

  if (!ctx) {
    throw new Error(
      'Context value undefined. Hook useProductDetailDispatch must be used within a ProductDetailProvider',
    )
  }

  return ctx
}
