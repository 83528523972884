import type { ReactElement } from 'react'

import { ProductDetailProvider } from '../../productDetails/ProductDetailProvider'
import AddToCartButton from '../../productDetails/AddToCartButton'
import Buybox from '../../productDetails/Buybox'
import QuantityField from '../../productDetails/QuantityField'
import QuantityFieldErrors from '../../productDetails/QuantityFieldErrors'

export default function Product({ product, shop, t }: ProductThemeComponentProps): ReactElement {
  return (
    <div className="template-product">
      <div>{product.name}</div>
      {product.image && (
        <div>
          <img src={product.image.url} />
        </div>
      )}
      <ProductDetailProvider productId={product.productId} minQuantity={product.orderUnitInfo.minOrder}>
        <div>
          <div>{t(product.availabilityText)}</div>
          {product.price && <div>{product.price.formatted}</div>}
          {product.basePrice && <div>{product.basePrice.formatted}</div>}
          <Buybox product={product}>
            <div>
              <div className="buybox-quantity-field">
                <QuantityField orderUnitInfo={product.orderUnitInfo} />
                <QuantityFieldErrors orderUnitInfo={product.orderUnitInfo} t={t} locale={shop.locale} />
              </div>
              <div className="add-to-cart-button">
                <AddToCartButton product={product} t={t}>
                  Add to cart
                </AddToCartButton>
              </div>
            </div>
          </Buybox>
        </div>
      </ProductDetailProvider>
    </div>
  )
}
