import type { ReactElement } from 'react'

import { getBulkPrice } from '../../utils/bulkPrices'
import { useProductDetailState } from './ProductDetailProvider'

export type PriceProps = {
  product: Frontend.Product
  className: string
}

export default function Price({ product, className }: Readonly<PriceProps>): ReactElement {
  const { quantity } = useProductDetailState()

  const price = product.bulkPrices ? getBulkPrice(product, quantity)?.formatted : product.price?.formatted

  return <div className={className}>{price}</div>
}
