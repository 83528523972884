import type { ReactElement } from 'react'

export type CustomAttribute = {
  key: string
  displayKey: string
  displayValue: string
}

type Props = {
  customAttributes: CustomAttribute[] | null
} & TranslateProps

export default function CustomAttributes({ t, customAttributes }: Readonly<Props>): ReactElement | null {
  if (!customAttributes || !customAttributes.length) return null

  return (
    <div className="product-addition">
      <h2 className="product-addition-headline">{t('components.productComponent.customAttributes')}</h2>
      {customAttributes.map((attribute) => (
        <dl
          className="product-addition-list"
          key={attribute.key}
          itemProp="additionalProperty"
          itemScope
          itemType="https://schema.org/PropertyValue"
        >
          <dt itemProp="name">{attribute.displayKey}</dt>
          <dd itemProp="value">{attribute.displayValue}</dd>
        </dl>
      ))}
    </div>
  )
}
