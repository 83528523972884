import type { ReactElement, Ref } from 'react'
import { useRef } from 'react'
import cc from 'classcat'
import loadable from '@loadable/component'

import { fetchVimeoThumbnail } from '../../../store/actions'
import { imageUrl } from '../../ThemeView'
import { useFetchedData } from '../../../utils/hooks/useFetchedData'
import LazyImage from '../../LazyImage'
import Swiper from '../../templateComponents/Workspace/plugins/contentslider/Swiper'
import VideoSlide from '../../templateComponents/Workspace/plugins/components/VideoComponent/VideoSlide'
import translate from '../../../utils/translate'

export const ZoomableImage = loadable(() => import(/* webpackChunkName: "Product" */ '../../ZoomableImage'))

type SlideData = {
  index: number
  url: string
  width: number
  height: number
  isZoomEnabled: boolean
  alt: string
  type: string
  previewImageUrl?: string
}

type ImageSlideProps = {
  slide: SlideData
  productName: string
  productLabels?: string[]
  isInView: boolean
}

const ImageSlide = translate()(function ImageSlide({
  slide: image,
  productName,
  productLabels,
  isInView,
  t,
}: ImageSlideProps & TranslateProps): ReactElement {
  const labels =
    productLabels && productLabels.length > 0 ? (
      <div className="product-image-label-wrapper">
        {productLabels.map((label) => (
          <span key={label} className={`product-image-label product-image-label-${label.toLowerCase()}`}>
            {t(`components.productComponent.imageLabels.${label}`)}
          </span>
        ))}
      </div>
    ) : null

  return (
    <>
      {image.isZoomEnabled ? (
        <ZoomableImage
          src={image.url}
          width={image.width}
          height={image.height}
          alt={productName}
          imageContainerSelector={'.product-image-gallery'}
          enlargedImagePortalId={'product-image-gallery-zoom'}
          isInView={isInView}
          overlayContent={labels}
        />
      ) : (
        <>
          <LazyImage
            src={image.url}
            width={image.width}
            height={image.height}
            alt={productName}
            style={{ maxWidth: image.width }}
            nofallback
          />
          {labels}
        </>
      )}
      <noscript>
        <img
          src={imageUrl(image.url, 600, 2560)}
          alt={productName}
          style={{ maxWidth: image.width }}
          itemProp="image"
        />
      </noscript>
    </>
  )
})

type SlideProps = {
  slide: SlideData
  isInView: boolean
  productName: string
  productLabels: string[]
}

function Slide({ slide, isInView, productName, productLabels }: Readonly<SlideProps>): ReactElement {
  return (
    <div className="swiper-slide">
      {slide.type === 'image' && <ImageSlide {...{ slide, productName, productLabels, isInView }} />}
      {['Vimeo', 'YouTube', 'YouTubeNoCookie'].includes(slide.type) && <VideoSlide {...{ slide, isInView }} />}
    </div>
  )
}

type ImageGalleryBulletsProps = {
  slides: SlideData[]
  slideTo: (index: number) => void
  slideIdx: number
}

function ImageGalleryBullets({ slides, slideTo, slideIdx }: Readonly<ImageGalleryBulletsProps>): ReactElement {
  return (
    <div className="product-image-gallery-bullets">
      {slides.map((slide) => (
        <div
          key={`slide-${slide.index}`}
          className={cc(['product-image-gallery-bullet', { active: slide.index === slideIdx }])}
          onClick={() => slideTo(slide.index)}
        />
      ))}
    </div>
  )
}

type ImageGalleryArrowsProps = {
  nextButtonRef: Ref<HTMLDivElement>
  prevButtonRef: Ref<HTMLDivElement>
}

function ImageGalleryArrows({ nextButtonRef, prevButtonRef }: Readonly<ImageGalleryArrowsProps>): ReactElement {
  return (
    <div className="swiper-arrows">
      <div ref={nextButtonRef} className="swiper-arrow-right" />
      <div ref={prevButtonRef} className="swiper-arrow-left swiper-button-disabled" />
    </div>
  )
}

type ThumbnailProps = {
  slide: SlideData
}

function Thumbnail({ slide }: Readonly<ThumbnailProps>): ReactElement {
  const [thumbnail] = useFetchedData(
    async () => {
      if (slide.previewImageUrl) return slide.previewImageUrl
      if (slide.type === 'Vimeo') return fetchVimeoThumbnail(slide.url)
      return ''
    },
    [slide],
    slide.previewImageUrl,
  )

  return (
    <div
      className={cc([
        'product-image-gallery-thumbnail-inner',
        {
          'video-thumbnail': slide.type !== `image`,
        },
      ])}
      style={{
        backgroundImage: `url("${imageUrl(thumbnail || slide.url, 85, 85)}")`,
      }}
    />
  )
}

type SwiperMainSlideContainerProps = {
  slides: SlideData[]
  slideIdx: number
  productName: string
  slideNext: () => void
  thumbnailListIsVertical?: boolean
  swiperContainerRef: Ref<HTMLDivElement>
  productLabels: string[]
}

function SwiperMainSlideContainer({
  slides,
  slideIdx,
  productName,
  productLabels,
  slideNext,
  thumbnailListIsVertical,
  swiperContainerRef,
}: Readonly<SwiperMainSlideContainerProps>): ReactElement {
  return (
    <div className="swiper-image-aspect-ratio">
      <div className="swiper-container swiper-main-image" ref={swiperContainerRef}>
        <div className="swiper-wrapper" onClick={() => !thumbnailListIsVertical && slideNext()}>
          {slides.map((slide, index) => (
            <Slide
              key={`slide-${slide.index}`}
              isInView={slideIdx === index}
              {...{ slide, productName, productLabels, swiperContainerRef }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const basicSliderSettings = {
  loop: false,
  keyboard: { enabled: true },
  grabCursor: true,
}

const visionThemeSliderSettings = {
  customControlClass: 'swiper-thumbnails',
  controller: { by: 'container' },
  zoom: { enabled: true },
}

type ImageGalleryProps = {
  product: Frontend.Product
  thumbnailListIsVertical?: boolean
  productLabels: string[]
}

export default function ImageGallery({
  product,
  thumbnailListIsVertical,
  productLabels,
}: Readonly<ImageGalleryProps>): ReactElement {
  const swiperContainerRef = useRef(null)
  const nextButtonRef = useRef(null)
  const prevButtonRef = useRef(null)

  const sliderSettings = Object.assign(basicSliderSettings, thumbnailListIsVertical ? visionThemeSliderSettings : {})

  const renderVerticalThumbnails = ({ slides, slideIdx, ready, slideTo }) => {
    return (
      <div className="product-image-gallery-thumbnails">
        <Swiper
          customControlled={true}
          slides={slides}
          direction={'vertical'}
          spaceBetween={0}
          slidesPerView={'auto'}
          centeredSlides={false}
          refSet={{ swiperContainerRef, nextButtonRef, prevButtonRef }}
          mousewheel={{ enabled: slides.length > 4 }}
          loop={false}
          renderSwiper={({ slides }) => (
            <>
              {slides.length > 4 && <div ref={prevButtonRef} className="swiper-arrow-top swiper-button-disabled" />}
              <div className="swiper-container swiper-thumbnails" ref={swiperContainerRef}>
                <div className="swiper-wrapper">
                  {slides.map((slide) => (
                    <div
                      key={`slide-${slide.index}`}
                      className={cc([
                        'swiper-slide',
                        'product-image-gallery-thumbnail-outer',
                        {
                          active: ready && slide.index === slideIdx,
                        },
                      ])}
                      onClick={() => slideTo(slide.index)}
                    >
                      <Thumbnail {...{ slide }} />
                    </div>
                  ))}
                </div>
              </div>
              {slides.length > 4 && <div ref={nextButtonRef} className="swiper-arrow-bottom" />}
            </>
          )}
        />
      </div>
    )
  }

  return (
    <div className="product-image-gallery">
      <div className="product-image-gallery-zoom-container">
        <div id="product-image-gallery-zoom" />
      </div>
      <Swiper
        key={product.productId}
        slides={product.slideshow}
        {...sliderSettings}
        refSet={{ swiperContainerRef, nextButtonRef, prevButtonRef }}
        renderSwiper={({ slides, slideIdx, ready, slideNext, slideTo }) => (
          <>
            {thumbnailListIsVertical && renderVerticalThumbnails({ slides, slideIdx, ready, slideTo })}
            <SwiperMainSlideContainer
              {...{ slides, slideIdx, slideNext, thumbnailListIsVertical, swiperContainerRef }}
              productName={product.name}
              productLabels={productLabels}
            />
            <ImageGalleryBullets {...{ slides, slideTo, slideIdx }} />
            <ImageGalleryArrows {...{ nextButtonRef, prevButtonRef }} />
            {!thumbnailListIsVertical && (
              <div className="product-image-gallery-thumbnails">
                {slides.map((slide) => (
                  <div
                    key={`slide-${slide.index}`}
                    className={cc(['product-image-gallery-thumbnail-outer', { active: slide.index === slideIdx }])}
                    onClick={() => slideTo(slide.index)}
                  >
                    <Thumbnail {...{ slide }} />
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      />
    </div>
  )
}
