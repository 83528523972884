import type { ComponentPropsWithoutRef, MouseEvent } from 'react'
import { uniqueId } from 'lodash'
import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cc from 'classcat'

import { GotoContext } from '../GotoProvider'
import { addLegacyProductProperties, track } from '../../utils/tracking'
import { addToCart, setThemeNotification } from '../../store/actions'
import { generateCartUrl } from '../../urlGenerators'
import { sendAddToCart } from '../../utils/googleAnalytics'
import { useProductDetailDispatch, useProductDetailState } from './ProductDetailProvider'
import { validateQuantity } from '../../utils/orderUnits'
import Link from '../templateComponents/Link'

export function getNotificationMessage(errMessage: string, t: TranslateProps['t'], count: number): string {
  if (/Low StockLevel/.test(errMessage)) return t('notifications.productAddedToCartError', { count })

  if (/Max allowed basket positions reached/.test(errMessage)) {
    const amount = /Max positions: (.*)./.exec(errMessage)?.[1]
    return t('notifications.maxAllowedBasketPositionsReachedError', { amount })
  }

  return errMessage
}

export interface AddToCartButtonProps extends ComponentPropsWithoutRef<'button'>, TranslateProps {
  product: Frontend.Product
  redirectToCart?: boolean
}

export default function AddToCartButton({
  type = 'button',
  product,
  className,
  t,
  redirectToCart = false,
  children,
  ...rest
}: Readonly<AddToCartButtonProps>) {
  const { isBusy, quantity, customizedText } = useProductDetailState()
  const dispatch = useProductDetailDispatch()
  const reduxDispatch = useDispatch()
  const { goto } = useContext(GotoContext)
  const isBeyond = useSelector<State, boolean>((state) => Boolean(state.getIn(['shop', 'beyond'])))
  const cartPossibleShippings = useSelector<State, any[]>((state) =>
    state.getIn(['cart', 'cartValidation', 'possibleShippingMethods'])?.toJS(),
  )
  // undefined when there are no restrictions
  const prdPossibleShippings = product.shippingMethodsRestrictedTo?.map((item) => item.title)
  const hasCustomizableText = Boolean(customizedText)
  const isCustomizationMissing = Boolean(product.customizableProductInfo && !hasCustomizableText)

  const hasValidQuantity =
    product.orderUnitInfo.orderUnit === 'piece'
      ? true
      : validateQuantity(quantity, product.orderUnitInfo.minOrder, product.orderUnitInfo.intervalOrder).length === 0

  const cartUrl = generateCartUrl()

  const handleClick = async (event: MouseEvent<HTMLButtonElement>) => {
    const hasRequiredFields = product.isVariationMaster || isCustomizationMissing

    if (!hasRequiredFields) {
      if (
        cartPossibleShippings &&
        prdPossibleShippings &&
        !cartPossibleShippings.some((cartPossible) => prdPossibleShippings.includes(cartPossible))
      ) {
        const notification = {
          id: uniqueId(`${product.productId}-failure-`),
          message: t(`notifications.noMatchingShippingMethods`),
          type: 'warning',
        }

        return reduxDispatch(setThemeNotification(notification))
      }

      try {
        dispatch({ type: 'update', payload: { isBusy: true } })

        const cart = await (reduxDispatch(
          addToCart(product.productId, quantity, product.customizableProductInfo ? customizedText : undefined, {
            showErrorNotification: false,
          }),
        ) as unknown as Promise<Frontend.Cart>)

        const notification = {
          id: uniqueId(`${product.productId}-success-`),
          message: (
            <span>
              {product.orderUnitInfo.orderUnit === 'piece'
                ? t('notifications.productAddedToCart', {
                    count: quantity,
                  })
                : t('notifications.productAddedToCart_one')}{' '}
              <Link className="add-to-cart-notice-message-link" to={cartUrl} key={product.productId}>
                {t('views.storefrontView.goToCartLink.label')}
              </Link>
            </span>
          ),
          type: 'success',
        }

        track('cart:add', { cart, product: addLegacyProductProperties(product, isBeyond), quantity })

        reduxDispatch(setThemeNotification(notification))
      } catch (err) {
        const notification = {
          id: uniqueId(`${product.productId}-failure-`),
          message: getNotificationMessage(err.toString(), t, quantity),
          type: 'failure',
        }

        reduxDispatch(setThemeNotification(notification))
      } finally {
        const resetQuantity = product.orderUnitInfo.minOrder ?? 1

        dispatch({
          type: 'update',
          payload: {
            isBusy: false,
            quantity: resetQuantity,
            quantityFieldValue: String(resetQuantity),
          },
        })
        sendAddToCart(product, quantity)
        if (redirectToCart) goto(generateCartUrl())
      }
    }

    const firstInvalidElement = Array.from(
      (event.target as typeof event.target & { form: HTMLFormElement }).form.elements,
    ).find((element: Element): element is HTMLInputElement | HTMLSelectElement => {
      return (
        (element instanceof HTMLInputElement || element instanceof HTMLSelectElement) &&
        'value' in element &&
        !element.value
      )
    })

    if (!firstInvalidElement) return

    // `focus()` on a `selection` element on iOS causes the selection
    // box to be opened and due to `scrollIntoView` being smooth on
    // iOS, the selection box will be displaced. In addition,
    // selecting a value from the selection box does not select
    // anything either. According to https://stackoverflow.com/a/37369135
    // using an immediately called `setTimeout` would not show the selection
    setTimeout(() => {
      firstInvalidElement.scrollIntoView({ block: 'center', behavior: 'auto' })
      firstInvalidElement.focus()
      // optional, otherwise we'd get the native texts
      firstInvalidElement.setCustomValidity(
        firstInvalidElement.nodeName === 'SELECT'
          ? t('components.productComponent.addToBasketButton.selectVariationTooltip')
          : t('components.productComponent.customization.tooltip', {
              customizableHeadlineText: product.customizableProductInfo?.customizableHeadlineText,
            }),
      )
      firstInvalidElement.reportValidity()
    }, 0)
  }

  return (
    <form id="add-to-cart-button">
      <button
        type={type}
        className={cc([className, { pending: isBusy }])}
        onClick={handleClick}
        disabled={isBusy || !(product.available || product.isVariationMaster) || !hasValidQuantity}
        {...rest}
      >
        {children}
      </button>
    </form>
  )
}
