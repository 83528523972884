import type { ChangeEvent, ReactElement } from 'react'
import { debounce } from 'lodash'
import { useRef, useState } from 'react'

import { useProductDetailDispatch } from './ProductDetailProvider'

export type CustomizableTextFieldProps = {
  customizableProductInfo: {
    customizableHeadlineText: string
    customizableTextLength: number
  }
} & TranslateProps

export default function CustomizableTextField({
  t,
  customizableProductInfo,
}: Readonly<CustomizableTextFieldProps>): ReactElement {
  const [text, setText] = useState('')
  const dispatch = useProductDetailDispatch()

  const setCustomizableText = useRef(
    debounce((customizedText: string) => {
      dispatch({
        type: 'update',
        payload: {
          customizedText,
        },
      })
    }, 300),
  )

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setText(evt.target.value)
    setCustomizableText.current(evt.target.value.trim())
  }

  return (
    <div className="product-customization-container">
      <label className="product-customization-field">
        <span className="product-customization-field-headline">{customizableProductInfo.customizableHeadlineText}</span>
        <p className="product-customization-field-text">
          {t('components.productComponent.customization.characterCounter', {
            used: text.length,
            limit: customizableProductInfo.customizableTextLength,
          })}
        </p>
        <input
          required
          form="add-to-cart-button"
          className="ep-form-text-field"
          type="text"
          maxLength={customizableProductInfo.customizableTextLength}
          value={text}
          onChange={handleChange}
        />
      </label>
    </div>
  )
}
