import { useProductDetailState } from './ProductDetailProvider'
import { validateQuantity } from '../../utils/orderUnits'

export type QuantityFieldErrorsProps = {
  orderUnitInfo: Core.OrderUnitInfo
  locale: string
} & TranslateProps

export default function QuantityFieldErrors({
  orderUnitInfo: { orderUnitShort, minOrder, intervalOrder },
  locale,
  t,
}: Readonly<QuantityFieldErrorsProps>) {
  const state = useProductDetailState()

  const decimalPlaces = intervalOrder.toString().split('.')[1]?.length ?? 0

  const formatter = new Intl.NumberFormat(locale.replace('_', '-'), {
    minimumFractionDigits: decimalPlaces,
  })

  const examples = [...Array(3).keys()]
    .map((factor) => `${formatter.format(factor * intervalOrder + minOrder)} ${orderUnitShort}`)
    .join(', ')

  const errors = validateQuantity(state.quantity, minOrder, intervalOrder)

  return (
    <div className="product-order-unit-quantity-field-errors">
      {errors.includes('minimumQuantityNotReached') && (
        <p>
          {t('components.productComponent.productOrderUnitQuantity.errors.minimumQuantityNotReached', {
            minimum: formatter.format(minOrder),
            unit: orderUnitShort,
          })}
        </p>
      )}
      {errors.includes('invalidQuantityIncrement') && (
        <p>
          {t('components.productComponent.productOrderUnitQuantity.errors.invalidQuantityIncrement', {
            step: formatter.format(intervalOrder),
            unit: orderUnitShort,
            examples,
          })}
        </p>
      )}
    </div>
  )
}
