import { getBulkPriceWhenExists } from '../../utils/bulkPrices'
import { useProductDetailState } from './ProductDetailProvider'

export interface PriceReferenceProps {
  lowestRefPriceFormatted: string | null
  basePriceFormatted: string
  bulkPrices: Core.BulkPrice[] | null
  className?: string
}

export default function PriceReference({
  lowestRefPriceFormatted,
  basePriceFormatted,
  bulkPrices,
  className,
}: Readonly<PriceReferenceProps>) {
  const { quantity } = useProductDetailState()

  return (
    <span className={className}>
      ({getBulkPriceWhenExists(bulkPrices, quantity) ?? lowestRefPriceFormatted ?? basePriceFormatted})
    </span>
  )
}
